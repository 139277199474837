import React from 'react'

class YouTubeRedirectPage extends React.Component {
  componentDidMount () {
    // window.location.replace('https://www.youtube.com/watch?v=jgMFj94Y4V4')
  }
  render () {
    return (
    <div>
    <iframe width="100%" height="780" src="https://www.youtube.com/embed/jgMFj94Y4V4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <div class="welcome-text text-right"><center><h1>RoboChef - The Robotic Kitchen</h1></center></div>
    </div>
    )
  }
}

export default YouTubeRedirectPage